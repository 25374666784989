/**
 * NOTICE OF LICENSE
 *
 * This source file is subject to a commercial license from SOLEDIS
 * Use, copy, modification or distribution of this source file without written
 * license agreement from the SOLEDIS GROUP is strictly forbidden.
 *    ___  ___  _    ___ ___ ___ ___
 *   / __|/ _ \| |  | __|   \_ _/ __|
 *   \__ \ (_) | |__| _|| |) | |\__ \
 *   |___/\___/|____|___|___/___|___/
 *
 *  @author    SOLEDIS <prestashop@groupe-soledis.com>
 *  @copyright 2024 SOLEDIS
 *  @license   All Rights Reserved
 *  @developer LE GUILLANT Thomas
 */

/**
 *
 * @param value
 * @param {boolean} zeroIsValid
 * @returns {boolean}
 */
export function empty (value, zeroIsValid = false) {
  let isEmptyValue = true
  if (isset(value)) {
    if (Array.isArray(value)) {
      if (value.length > 0) {
        isEmptyValue = false
      }
    } else if (typeof value === 'object') {
      if (Object.entries(value).length > 0 ||
        (isset(value.nodeType) && value.nodeType > 0)) {
        isEmptyValue = false
      }
    } else if (typeof value === 'boolean') {
      if (value) {
        isEmptyValue = false
      }
    } else if (value.toString() !== '') {
      isEmptyValue = false
      if (!zeroIsValid) {
        if (typeof value === 'string') {
          if (value === '0') {
            isEmptyValue = true
          }
        } else if (typeof value === 'number') {
          if (value === 0) {
            isEmptyValue = true
          }
        }
      }
    }
  }
  return isEmptyValue
}

/**
 *
 * @param value
 * @returns {boolean}
 */
export function isset (value) {
  let issetValue = false
  if (typeof value !== 'undefined' && value !== null) {
    issetValue = true
  }
  return issetValue
}

/**
 *
 * @param {string} value
 * @returns {boolean}
 */
export function isValidDate (value) {
  let isValidateDate = false
  if (!empty(value) && !empty(value.toString())) {
    isValidateDate = !isNaN(new Date(value.toString()))
  }
  return isValidateDate
}
