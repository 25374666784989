import JsonResponse from "./JsonResponse";

/**
 * NOTICE OF LICENSE
 *
 * This source file is subject to a commercial license from SOLEDIS
 * Use, copy, modification or distribution of this source file without written
 * license agreement from the SOLEDIS GROUP is strictly forbidden.
 *    ___  ___  _    ___ ___ ___ ___
 *   / __|/ _ \| |  | __|   \_ _/ __|
 *   \__ \ (_) | |__| _|| |) | |\__ \
 *   |___/\___/|____|___|___/___|___/
 *
 * @author    SOLEDIS <prestashop@groupe-soledis.com>
 * @copyright 2024 SOLEDIS
 * @license   All Rights Reserved
 * @developer LE GUILLANT Thomas
 */
export default class Ajax {
    callAjaxRequest(url, method, callback, params = {}) {
        let ajaxRequest = new XMLHttpRequest()
        ajaxRequest.open(method, url, true)
        ajaxRequest.setRequestHeader('Content-Type',
            'application/json;charset=UTF-8')
        ajaxRequest.onreadystatechange = function () {
            if (ajaxRequest.readyState === ajaxRequest.DONE) {
                callback(params, new JsonResponse(JSON.parse(ajaxRequest.response)))
            }
        }
        ajaxRequest.send(JSON.stringify(params))
    }
}
