/**
 * NOTICE OF LICENSE
 *
 * This source file is subject to a commercial license from SOLEDIS
 * Use, copy, modification or distribution of this source file without written
 * license agreement from the SOLEDIS GROUP is strictly forbidden.
 *    ___  ___  _    ___ ___ ___ ___
 *   / __|/ _ \| |  | __|   \_ _/ __|
 *   \__ \ (_) | |__| _|| |) | |\__ \
 *   |___/\___/|____|___|___/___|___/
 *
 * @author    SOLEDIS <prestashop@groupe-soledis.com>
 * @copyright 2024 SOLEDIS
 * @license   All Rights Reserved
 * @developer LE GUILLANT Thomas
 */
import {isset} from './defaultFunction.js'

export default class JsonResponse {
    private readonly _success: boolean;
    private readonly _msgError: string[];
    private readonly _msgSuccess: string[];
    private readonly _data: any

    constructor(jsonResponseFromAjax) {
        if (isset(jsonResponseFromAjax.success)) {
            this._success = jsonResponseFromAjax.success;
        }
        if (isset(jsonResponseFromAjax.msgError)) {
            this._msgError = jsonResponseFromAjax.msgError;
        }
        if (isset(jsonResponseFromAjax.msgSuccess)) {
            this._msgSuccess = jsonResponseFromAjax.msgSuccess;
        }
        if (isset(jsonResponseFromAjax.data)) {
            this._data = jsonResponseFromAjax.data;
        }

    }

    get success(): boolean {
        return this._success;
    }

    get msgError(): string[] {
        return this._msgError;
    }

    get msgSuccess(): string[] {
        return this._msgSuccess;
    }

    get data(): any {
        return this._data;
    }
}
