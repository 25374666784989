import {empty, isset} from '../../component/defaultFunction'
import Module from './Module'

/**
 * @typedef {Module} moduleSldApeManagement
 */
let moduleSldApeManagement = null
if (!empty(sldapemanagement)) {
    moduleSldApeManagement = new Module(sldapemanagement)
}
document.addEventListener('DOMContentLoaded', function () {
    if (isset(moduleSldApeManagement)) {
        const divModalSaleParticipate = document.querySelector('#' + Module.idDivModalSaleParticipate),
            buttonSubmitSaleParticipate = document.querySelector('#' + Module.idButtonSubmitSaleParticipate),
            buttonQuitCurrentSale = document.querySelector('#' + Module.idButtonQuitCurrentSale)
        if (!empty((divModalSaleParticipate)) && !empty(buttonSubmitSaleParticipate)) {
            const inputSaleReference = document.querySelector('#' + Module.idInputSaleReference),
                inputSaleChoose = document.querySelector('#' + Module.idInputSaleChoose)
            if (!empty(inputSaleChoose) && !empty(inputSaleReference)) {
                inputSaleChoose.addEventListener('change', onChangeInputSaleChooseParticipate)
                inputSaleReference.addEventListener('focusin', onFocusInInputSaleReference)
            }
            buttonSubmitSaleParticipate.addEventListener('click', onClickButtonSubmitSaleParticipate)
            if (!empty(buttonQuitCurrentSale)) {
                buttonQuitCurrentSale.addEventListener('click', onClickButtonQuitCurrentSale)
            }
        }
    }
})

function onClickButtonSubmitSaleParticipate() {
    const inputSaleReference = document.querySelector('#' + Module.idInputSaleReference),
        inputSaleChoose = document.querySelector('#' + Module.idInputSaleChoose)
    if (!empty(inputSaleReference) && !empty(inputSaleReference.value)) {
        moduleSldApeManagement.participateToSale(inputSaleReference.value)
    } else if (!empty(inputSaleChoose) && !empty(inputSaleChoose.value)) {
        moduleSldApeManagement.participateToSale(inputSaleChoose.value)
    } else {
        moduleSldApeManagement.participateToSale('0')
    }
}

function onChangeInputSaleChooseParticipate(event) {
    /**
     *
     * @type {HTMLInputElement}
     */
    const inputSaleReference = document.querySelector('#' + Module.idInputSaleReference)
    if (!empty(event.currentTarget.value)) {
        if (!empty(inputSaleReference)) {
            inputSaleReference.value = ''
        }
    }
}

function onFocusInInputSaleReference() {
    /**
     *
     * @type {HTMLSelectElement}
     */
    const inputSaleChoose = document.querySelector('#' + Module.idInputSaleChoose)
    if (!empty(inputSaleChoose.value)) {
        inputSaleChoose.value = '0'
    }
}

function onClickButtonQuitCurrentSale() {
    moduleSldApeManagement.quitCurrentSale()
}
