/**
 * NOTICE OF LICENSE
 *
 * This source file is subject to a commercial license from SOLEDIS
 * Use, copy, modification or distribution of this source file without written
 * license agreement from the SOLEDIS GROUP is strictly forbidden.
 *    ___  ___  _    ___ ___ ___ ___
 *   / __|/ _ \| |  | __|   \_ _/ __|
 *   \__ \ (_) | |__| _|| |) | |\__ \
 *   |___/\___/|____|___|___/___|___/
 *
 * @author    SOLEDIS <prestashop@groupe-soledis.com>
 * @copyright 2024 SOLEDIS
 * @license   All Rights Reserved
 * @developer LE GUILLANT Thomas
 */
import {empty, isset} from '../../component/defaultFunction.js'
import Ajax from "../../component/Ajax";
import JsonResponse from "../../component/JsonResponse";

export default class Module {
    private static readonly _idDivModalSaleParticipate = 'modal-sale-participate'
    private static readonly _idButtonSubmitSaleParticipate = 'button-submit-sale-participate'
    private static readonly _idButtonQuitCurrentSale = 'button-quit-current-sale'
    private static readonly _nameInputSaleReference = 'sale_reference';
    private static readonly _idInputSaleReference = 'input-sale-participate-reference';
    private static readonly _idInputSaleChoose = 'input-sale-choose-participate';
    private static readonly _idDivAlertErrorJs = 'div-alert-js-error';

    constructor(moduleConfiguration: object) {
        if (isset(moduleConfiguration.ajaxUrl)) {
            this._ajaxUrl = moduleConfiguration.ajaxUrl
        } else {
            this._ajaxUrl = '';
        }
        this._ajaxClass = new Ajax();
    }

    static get idDivAlertErrorJs(): string {
        return this._idDivAlertErrorJs;
    }

    static get idDivModalSaleParticipate(): string {
        return this._idDivModalSaleParticipate;
    }

    static get idButtonSubmitSaleParticipate(): string {
        return this._idButtonSubmitSaleParticipate;
    }

    static get nameInputSaleReference(): string {
        return this._nameInputSaleReference;
    }

    static get idInputSaleReference(): string {
        return this._idInputSaleReference;
    }

    static get idInputSaleChoose(): string {
        return this._idInputSaleChoose;
    }

    static get idButtonQuitCurrentSale(): string {
        return this._idButtonQuitCurrentSale;
    }

    private _ajaxUrl: string

    get ajaxUrl(): string {
        return this._ajaxUrl;
    }

    private _ajaxClass: Ajax;

    get ajaxClass(): Ajax {
        return this._ajaxClass;
    }

    participateToSale(saleReference: string): void {
        this.ajaxClass.callAjaxRequest(this.ajaxUrl, 'POST', this.callbackParticipateToSale.bind(this), {
            saleReference: saleReference,
            action: 'participate'
        })
    }

    quitCurrentSale(): void {
        this.ajaxClass.callAjaxRequest(this.ajaxUrl, 'POST', this.callbackQuitCurrentSale.bind(this), {
            action: 'quitCurrentSale'
        })
    }

    callbackParticipateToSale(params: object, jsonResponse: JsonResponse): void {
        if (!jsonResponse.success) {
            this.showErrorMessages(jsonResponse.msgError)
        } else if (!empty(jsonResponse.data.redirect_url)) {
            window.location.replace(jsonResponse.data.redirect_url);
        } else {
            window.location.reload();
        }
    }

    callbackQuitCurrentSale(params: object, jsonResponse: JsonResponse): void {
        if (!jsonResponse.success) {
            this.showErrorMessages(jsonResponse.msgError)
        } else if (!empty(jsonResponse.data.redirect_url)) {
            window.location.replace(jsonResponse.data.redirect_url);
        } else {
            window.location.reload();
        }
    }

    showErrorMessages(msgError: string[]): void {
        const divAlertErrorJs = document.querySelector('#' + Module.idDivAlertErrorJs);
        if (!empty(divAlertErrorJs)) {
            divAlertErrorJs.innerHTML = '';
            divAlertErrorJs.classList.remove('d-none');
            msgError.forEach((error) => {
                const p = document.createElement('p');
                p.innerText = error;
                divAlertErrorJs.append(p);
            })
        } else {
            msgError.forEach((error) => {
                alert(error)
            })
        }
    }
}
